<template>
    <div style="margin: 0;background-color: #f5f8fa;">
        <van-tabs v-model="activeIndex" @change="changeEvent" animated swipeable sticky>
            <van-tab v-for="(item, index) in tabBars" :key="index" :title="item.name">
                <!-- 下拉刷新 -->
                <van-pull-refresh v-if="pages.length" v-model="pages[index].isLoading" @refresh="onRefresh">
                    <!-- 上拉加载更多 -->
                    <van-list
                        v-model="pages[index].loading"
                        :finished="pages[index].finished"
                        finished-text=""
                        @load="onLoad"
                    >
                        <div v-if="pages[index].data">
                            <div v-for="(item1,index1) in videoList[index].list" :key="index1" class="br-10" style="margin: 0 15px 20px;background:#E0E0E0; box-shadow: 0 2px 7px 0 rgba(5,34,97,.1);">
                                <div class="test_two_box m-t-20">

                                    <video :id="'V'+item1.vid+index" class="video-js">
                                        <source :src="item1.fhash" type="application/x-mpegURL" />
                                    </video>
                                </div>

                                <div class="m-10" @click="openVideo(item1.vid)">
                                    <van-tag plain type="success" size="medium">{{tabBars[item1.type].name}}</van-tag>
                                </div>
                                <div class="m-10 font-13 grey" @click="openVideo(item1.vid)">发布时间：{{item1.created_at}}</div>
                                <div class="m-10 padding-b-10"  @click="openVideo(item1.vid)">{{item1.title}}</div>
                            </div>
                        </div>
                        <div v-else>
                            <van-empty :description="$common.noDataText" />
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import Tab from '../../../public/js/video-tab.js'
    
	export default {
        components:{
        },
		data() {
			return {
                activeIndex:0,
                tabBars:Tab.tab,
				videoList:[],
                pages:[],
                videoObj:[],
			}
		},
        beforeMount() {
            this.dataList()
            this.getVideo()
        },
		methods: {
            openVideo(vid){
                this.$router.push({
                    path:'/video/details',
                    query:{
                        vid:vid
                    }
                })
            },
            onRefresh(){  // 上拉刷新
                setTimeout(() => {
                    this.pages[this.activeIndex].page = 1
                    this.pages[this.activeIndex].isLoading = false;
                    this.pages[this.activeIndex].loading = false
                    this.getVideo()
                    console.log('刷新成功')
                }, 1000);
                
            },
            onLoad() { // 上拉加载更多
                setTimeout(() => {
                    this.getVideo()
                }, 1500);
            },
            changeEvent(index){
                this.activeIndex = index

                if(index > 0) this.getVideo()
            },
            
            dataList(){
                let arr = []
                let pages = []
                for(var i=0; i<this.tabBars.length; i++){
                    let list = {list:[]}
                    arr.push(list)
                    let page = {page:1,isLoading:false,loading:false,finished:false,data:true}
                    pages.push(page)
                }
                this.videoList = arr
                this.pages = pages
            },            
            
			getVideo(){
                let index = this.activeIndex
                let page = this.pages[index].page
                
                if(this.pages[this.activeIndex].loading){
                    this.pages[index].finished = true
                    return false
                }
                if(index == 0){
                    this.$api.video.getVideoList().then((res) => {                        
                        this.videoList[0].list = res.data
                        this.result(res.data, res.meta, page)
                    })
                }else{
                    this.$api.video.getVideoByTypeId(index).then((res) => {
                        this.videoList[index].list = res.data                        
                        this.result(res.data, res.meta, page)
                    })
                }
            },
            
            result(data, meta, page){
                if(data.length == 0){
                    this.pages[this.activeIndex].data = false
                }
                if(page == 1){
                    this.videoList[this.activeIndex].list = data
                }else{
                    this.videoList[this.activeIndex].list = [...this.videoList[this.activeIndex].list,...data]
                }
                if(data.length >= meta.per_page){
                    this.pages[this.activeIndex].page++
                    this.pages[this.activeIndex].loading = false
                }else{
                    this.pages[this.activeIndex].finished = true
                }
                
                for(let i=0; i<data.length; i++){
                    setTimeout(()=>{
                        this.videoEvent(data[i].vid+this.activeIndex)
                    },200)
                }
            },
            
            // 暂停所有播放
            suspendVideo(vid){                
                for(let i=0;i<this.videoObj.length;i++){
                    if(this.videoObj[i].id_ != 'V'+vid){
                        this.videoObj[i].pause()
                    }
                }
            },
            
            videoEvent(vid){
                for(let i=0;i<this.videoObj.length;i++){
                    if(this.videoObj[i].id_ == 'V'+vid){
                        return false
                    }
                }
                
                let t = this
                let video = this.$video('V'+vid, {
                    //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                    controls: true,
                    poster: '/videos/cover.png', //封面
                    //自动播放属性,muted:静音播放
                    // autoplay: "muted",
                    //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                    // preload: "auto",
                    //设置视频播放器的显示宽度（以像素为单位）
                    // width: "300",
                    //设置视频播放器的显示高度（以像素为单位）
                    // height: "300",

                },
                function onPlayerReady(){
                    this.on("playing", function(){
                        // 暂停其他视频
                        t.suspendVideo(vid)
                    });
                });
                this.videoObj.push(video)
            },
            // 销魂所有视频实例
            videoDispose(){
                for(let i=0;i<this.videoObj.length;i++){
                    this.videoObj[i].dispose()
                }
            }
		},
        beforeDestroy() {
            this.videoDispose()
        }
	}
</script>

<style>
    .van-tabs__content--animated{
        background-color: #FFFFFF;
    }

    .video-js{
        width: 100% !important;
        min-height: 186px !important;
        height: auto;
    }

    
    .vjs-poster{
        background-size: auto;
    }
    
    .vjs-paused .vjs-big-play-button,
    .vjs-paused.vjs-has-started .vjs-big-play-button {
        display: block;
    } 
    .video-js .vjs-big-play-button{
        font-size: 2.5em;
        line-height: 2.3em;
        height: 2.5em;
        width: 2.5em;
        -webkit-border-radius: 2.5em;
        -moz-border-radius: 2.5em;
        border-radius: 2.5em;
        background-color: #73859f;
        background-color: rgba(115,133,159,.5);
        border-width: 0.15em;
        position: absolute;
        top: 33%;
        left: 40%;
    }
    /* 中间的播放箭头 */
    .vjs-big-play-button .vjs-icon-placeholder {
        font-size: 1.63em;
    }
    /* 加载圆圈 */
    .vjs-loading-spinner {
        font-size: 2.5em;
        width: 2em;
        height: 2em;
        border-radius: 1em;
        margin-top: -1em;
        margin-left: -1.5em;

    }
</style>
